.editFeatureOuterBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 1.5rem;
  height: 74vh;
  overflow: scroll;
  box-shadow:
    rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

.backgroundOuterBox {
  margin-left: 5%;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.viewbackgroundStepsBox {
  margin-left: 4%;
  display: flex;
  flex-direction: column;
}

.scenarioListBox {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.scenarioBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scenarioText {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 1rem;
}

.accordionDetailsCss {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.viewtextfieldTagCss {
  width: 98%;
  margin-left: 1rem;
}

.viewScenarioStepsBox {
  margin-left: 5rem;
}

.viewActionButtonBox {
  margin: 3vh 5% 2vh 85%;
  justify-content: flex-end;
}

.actionButtonsBox {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0.5rem 1.5rem;
  position: fixed;
  bottom: 2rem;
  width: -webkit-fill-available;
  background-color: white;
  box-shadow:
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.2);
}
