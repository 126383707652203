.popupDropDownBox {
  margin: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.checkboxOuterBox {
  display: flex;
  padding: 0.5rem;
}
.checkBoxAndTextCss {
  padding: 0.5rem 0.5rem;
}
.popupTextFieldBox {
  display: flex;
  padding: 1rem;
}
.popupColumnTextFieldCss {
  padding-right: 0.5rem;
}
.popupRowTextFieldCss {
  margin-left: 0.5rem;
}
