.changepasswordContainer {
  width: 30%;
  height: auto;
  padding-top: 2%;
  padding-bottom: 2%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transition: transform 0.3s ease;
}

.changepasswordFormContainer {
  padding-top: 5%;
  padding-bottom: 5%;
}

.passwordMessage {
  color: red;
  font-size: 12px;
  text-decoration: underline;
}

.loginLink {
  text-decoration: underline;
  font-size: small;
  padding-left: 60%;
  color: rgb(254, 82, 74);
}
