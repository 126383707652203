.runScenarioInnerBox {
  padding: 1.5rem;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  width: 40rem;
  min-width: max-content;
}
.runScenarioOuterBox {
  display: flex;
  padding-top: 2.5%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: max-content;
}
