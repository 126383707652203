.viewFeatureStepOuterBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  margin: 8px;
}

.viewFeatureStepInnerBox {
  display: flex;
  overflow: scroll;
  gap: 1rem;
  margin-left: 3.9rem;
}
