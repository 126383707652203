.copyright {
  background-color: #1f2126;
  padding: 4px;
  color: white;
  width: 100%;
  text-align: center;
  font-size: 1rem;
  position: fixed;
  bottom: 0;
}
