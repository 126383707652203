.permissionsOutterBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-height: 70vh;
  overflow: scroll;
  margin: 2rem 1rem;
}

.permissionsBox {
  min-width: 16rem;
  padding: 16px;
  display: flex;
  gap: 16px;
}

.actionBox {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0.5rem 1rem;
  position: fixed;
  bottom: 2rem;
  width: -webkit-fill-available;
  background-color: white;
  white-space: nowrap;
  overflow-x: auto;
  box-shadow:
    0px 2px 2px -2px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.actionButtonsBox {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
