.paginationOuterStack {
  align-items: center;
  display: flex;
  gap: 8px;
  width: 15rem;
}

.paginationDisable {
  cursor: no-drop;
  color: #4f4f4f;
}

.paginationTextFieldCss {
  width: 2%;
  min-width: 5%;
  display: flex;
  align-items: center;
}

.paginationTextFieldCss input {
  font-size: 13px;
}

.paginationTextFieldCss input::-webkit-outer-spin-button,
.paginationTextFieldCss input::-webkit-inner-spin-button {
  display: none;
}

.paginationEnable {
  cursor: pointer;
  color: #fe524a;
}
