.outterBoxCss {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 9px;
}

.loaderBoxCss1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.loaderBoxCss2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}
