.actionBox {
  display: flex;
  /* justify-content: center; */
  gap: 1rem;
  padding: 0.5rem 1rem;
  position: fixed;
  bottom: 2rem;
  width: -webkit-fill-available;
  background-color: white;
  overflow: scroll;
  box-shadow:
    0px 2px 2px -2px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
