.appbarCss {
  background-color: white;
  height: 60px;
}
.toolbarOuterBox {
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: space-between;
}
.toolbarInnerBox {
  display: flex;
  float: left;
  align-items: center;
}
.menuIconButtonCss {
  margin-right: 16px;
  color: #fe524a;
}
.navbarImageOuterBox {
  display: flex;
  float: right;
  gap: 16px;
}
.rsLogoCss {
  width: 80px;
  height: 40px;
}
.accountCircleCss {
  color: #fe524a;
}
.menuOpenCss {
  margin-left: -16px;
}
.menuListOuterBox {
  width: 200px;
}
.menuCloseIconCss {
  float: right;
}
.popperCardCss {
  position: fixed;
  background-color: #faefef;
  top: 10%;
  right: -2em;
  width: 12em;
  margin: 0.5em;
  z-index: 1;
}
.popperMenuItemCss {
  color: #fe524a;
  padding-top: 16px;
  padding-left: 8px;
}
.popperDividerCss {
  margin: 0.5rem;
}
