.outterBox1 {
  width: 30%;
  position: absolute;
  height: auto;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50px);
}

.outterBox2 {
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.inputBox {
  padding: 0rem 2rem 1rem;
  display: grid;
  gap: 1rem;
}

.actionBox {
  display: flex;
  justify-content: space-around;
  padding: 1rem 0rem;
}

.loginLink {
  text-decoration: underline;
  font-size: small;
  display: flex;
  justify-content: end;
  padding: 0rem 3rem;
  color: rgb(254, 82, 74);
}

.errorMessage {
  color: #fe524a;
  font-size: 12px;
  text-decoration: underline;
}

.passwordMessage {
  color: #fe524a;
  font-size: 12px;
  text-decoration: underline;
}
