.NoFilesMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  color: #f28680;
}

.featureTopButtonBox {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding: 16px;
}

.featurePaginationBox {
  margin-right: 2%;
}

.featureItemsOuterBox {
  display: flex;
  justify-content: flex-start;
  gap: 1%;
  flex-wrap: wrap;
  overflow: scroll;
  margin-bottom: 4rem;
}

.featureCardCss {
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  width: 15rem;
  margin: 1rem;
}

.featureCardCss:hover {
  box-shadow:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.featureCardActionsCss {
  padding: 16px 0 0;
  display: flex;
  gap: 8px;
}

.featureIconButtonCss {
  padding: 0px;
}

.cloneDialogBody {
  display: flex;
  flex-direction: column;
  gap: 3;
  justify-content: space-between;
  min-width: 250px;
  min-height: 150px;
}

.cloneDialogBodyActions {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}

.actionBox {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.5rem 1rem;
  position: fixed;
  bottom: 2rem;
  width: -webkit-fill-available;
  background-color: white;
  white-space: nowrap;
  overflow-x: auto;
  box-shadow:
    0px 2px 2px -2px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.actionButtonsBox {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.listItemCss {
  display: list-item;
}

.listCss {
  list-style-type: disc;
  padding: 0;
  padding-left: 20px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Adjust opacity as needed */
  backdrop-filter: blur(2px);
  z-index: 999;
}

.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
