.notifyWarningHeader {
  display: flex;
  justify-content: flex-start;
  gap: 30%;
  align-items: center;
  padding-bottom: 5%;
}

.warnIcon {
  color: #f1c40f;
}
