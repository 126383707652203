.scheduling-form {
  min-width: 520px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.scheduling-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.days-of-week {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
}

.spacebetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.spacebetween label {
  width: 50%;
}

.chip {
  margin: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: gray;
  background-color: #f9f9f9;
}

.chip.selected {
  border-color: #fe524a; /* primary.main equivalent */
  border-width: 2px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.typography {
  font-size: 18px;
  font-weight: 500; /* "medium" is typically represented as 500 */
  align-items: center;
  /* width: 40%; */
  justify-content: left;
  text-align: right;
  float: left;
}
