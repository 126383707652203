.editFeatureStepOuterBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  gap: 1rem;
}

.editFeatureStepInnerBox {
  /* width: 93%; */
  display: flex;
  overflow: scroll;
  gap: 8px;
}
.selectOutterBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem;
  width: 100%;
}
.selectInnerLeft {
  width: 20%;
}
.selectInnerRight {
  width: 80%;
  display: flex;
  gap: 8px;
}
.selectBox {
  padding-top: 3%;
}
.datatableBox {
  overflow-x: auto;
}
