.notFoundOuterBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 50%;
  margin: auto;
  margin-top: 12%;
}
.notFoundIcon {
  font-size: 500%;
}
.notFoundTypography {
  text-align: center;
}
