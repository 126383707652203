.rootContainer {
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin: 1rem 6rem 0rem;
}

.actionBox {
  display: flex;
  justify-content: end;
  gap: 1rem;
  padding: 0.5rem 1rem;
  position: fixed;
  bottom: 2rem;
  width: -webkit-fill-available;
  background-color: white;
  white-space: nowrap;
  overflow-x: auto;
  box-shadow:
    0px 2px 2px -2px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.actionButtonsBox {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.warningPopUpBox {
  display: flex;
  align-items: center;
  gap: 16px;
}

.stackBox {
  display: flex;
  justify-content: space-between;
  width: 50%;
  gap: 8px;
}

.typographyBox {
  width: 20%;
  align-self: center;
}

input[type="text"]:disabled,
input[type="email"]:disabled {
  background-color: #e0e0e0;
}
