body,
html {
  font-family: "Lato", "sans-serif";
}

/* place global styles here */
.noWrap {
  font-weight: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loaderIconStylings {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  margin-right: 7rem;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
