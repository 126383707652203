.logincontainer {
  width: 30%;
  height: auto;

  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transform: translate(-50%, -50%);
  align-items: center;
}

.loginformcontainer {
  padding-top: 5%;
  padding-bottom: 7%;
}

.loginlink {
  text-decoration: underline;
  font-size: small;
  padding-left: 60%;
  color: rgb(254, 82, 74);
}

.errormessage {
  color: red;
  font-size: 12px;

  text-decoration: underline;
}

.passwordmessage {
  color: red;
  font-size: 12px;
  text-decoration: underline;
}
.passworderrorcontainer {
  margin-left: 1%;
}
