.featureCardCss {
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  width: 15rem;
  margin: 1rem;
}

.featureCardCss:hover {
  box-shadow:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.featureCardActionsCss {
  padding: 16px 0 0;
  display: flex;
  gap: 16px;
}

.featureIconButtonCss {
  padding: 0px;
}
