.rolesBox {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: scroll;
  margin: 2rem 0rem 2rem 2rem;
  max-height: 70vh;
}

.roleCardCss {
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  width: 15rem;
  height: 6rem;
  margin: 1rem;
}

.roleCardCss:hover {
  box-shadow:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.typographyBox {
  width: 20%;
  align-self: center;
}

.actionBox {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0.5rem 1rem;
  position: fixed;
  bottom: 2rem;
  width: -webkit-fill-available;
  background-color: white;
  white-space: nowrap;
  overflow-x: auto;
  box-shadow:
    0px 2px 2px -2px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.actionButtonsBox {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
