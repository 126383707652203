.rootContainer {
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin: 1rem 6rem;
}

.actionBox {
  display: flex;
  justify-content: end;
  gap: 1rem;
  padding: 0.5rem 1rem;
  position: fixed;
  bottom: 2rem;
  width: -webkit-fill-available;
  background-color: white;
  white-space: nowrap;
  overflow-x: auto;
  box-shadow:
    0px 2px 2px -2px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.actionButtonsBox {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.stackBox {
  display: flex;
  justify-content: space-between;
  width: 50%;
  align-items: center;
}

.typographyBox {
  width: 40%;
  align-self: center;
}

.typographyBox1 {
  width: 20%;
  align-self: center;
}

.warningPopUpBox {
  display: flex;
  align-items: center;
  gap: 16px;
}

input[type="text"]:disabled,
input[type="email"]:disabled {
  background-color: #e0e0e0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Adjust opacity as needed */
  backdrop-filter: blur(2px);
  z-index: 999;
}

.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
