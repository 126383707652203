.filtersOuterBox {
  display: flex;
  justify-content: left;
  gap: 1rem;
  margin-top: 6px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.formControlCss {
  width: 15%;
  min-width: 10rem;
}
.selectComponentCss {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.dateTimePickerCss {
  width: 22%;
  min-width: 10rem;
}
.buttonsOuterBox {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  margin-top: 8px;
  margin-bottom: 8px;
}
