.reuseDropDownOuterBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.reuseDropDownInnerBox {
  display: flex;
  gap: 8px;
}
.reuseDropDownTypoField {
  padding: 8px;
}
.reuseDropDownTectFieldBox {
  display: flex;
  margin-left: 4.3%;
  gap: 8px;
  overflow: scroll;
}
.reuseTextFieldCss {
  max-width: max-content;
  /* padding-left: 2%; */
}
