.scenarioBox {
  padding: 1.5rem;
  height: 78.5vh;
  overflow: scroll;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
}

.viewtextfieldTagCss {
  width: 98%;
  margin-left: 1rem;
}

.inputTags {
  color: #198b8b;
}

.viewScenarioStepsBox {
  margin-left: 3rem;
}

.actionButtonsBox {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0.5rem 1.5rem;
  position: fixed;
  bottom: 2rem;
  width: -webkit-fill-available;
  background-color: white;
  box-shadow:
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.2);
}
