.editFeatureOuterBox {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  height: 78.5vh;
  overflow: scroll;
}

.backgroundOuterBox {
  margin-left: 5%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.backgroundInnerBox {
  display: flex;
  flex-direction: row;
}

.backgroundStepsBox {
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.scenarioListBox {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.scenarioBox {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.scenarioText {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 8px;
}

.svgIcon {
  padding: 0;
}

.accordionDetailsCss {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.autoCompleteTagCss {
  padding-left: 8px;
  width: 100%;
}

.scenarioNameBox {
  display: flex;
  flex-direction: row;
}

.scenarioStepsBox {
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.actionButtonsBox {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0.5rem 1.5rem;
  position: fixed;
  bottom: 2rem;
  width: -webkit-fill-available;
  background-color: white;
  box-shadow:
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.warningPopUpBox {
  display: flex;
  align-items: center;
  gap: 16px;
}
